import { AppBar, Button, Toolbar, Typography } from "@material-ui/core";
import styles from "./AppShell.module.css";
import { NavHashLink, HashLink } from "react-router-hash-link";

function AppShell() {
  return (
    <AppBar position="sticky" className={styles.appShell}>
      <Toolbar>
        <Typography
          className={styles.logo}
          variant="h3"
          style={{ flexGrow: 1, textAlign: "left" }}
        >
          <HashLink ca smooth to={"/#home"}>
            Stuart
          </HashLink>
        </Typography>
        <HashLink smooth to={"/#about"}>
          <Button className={styles.button} color="black">
            About
          </Button>
        </HashLink>
        <HashLink smooth to={"/#skills"}>
          <Button className={styles.button} color="black">
            Skills
          </Button>
        </HashLink>
        <HashLink smooth to={"/#experience"}>
          <Button className={styles.button} color="black">
            Experience
          </Button>
        </HashLink>
        <HashLink smooth to={"/#contact"}>
          <Button className={styles.button} color="black">
            Contact
          </Button>
        </HashLink>

        {/* <Typography
          //   className={styles.logo}
          //   variant="h3"
          style={{ textAlign: "right" }}
        >
          About
        </Typography>{" "}
        <Typography
          //   className={styles.logo}
          //   variant="h3"
          style={{ textAlign: "right" }}
        >
          Skills
        </Typography>{" "}
        <Typography
          //   className={styles.logo}
          //   variant="h3"
          style={{ textAlign: "right" }}
        >
          Project
        </Typography>{" "}
        <Typography
          //   className={styles.logo}
          //   variant="h3"
          style={{ textAlign: "right" }}
        >
          Contact
        </Typography> */}
      </Toolbar>
    </AppBar>
  );
}

export default AppShell;
