import styles from "./Footer.module.css";

function Footer() {
  return (
    <div className={styles.FooterContainer}>
      <p className={styles.copyrightMessage}>
        Copyright © 2021 Stuart Long. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
