const experienceData = [
  {
    id: 1,
    title: "Software Engineering Intern",
    location: "Jones Lang Lasalle",
    description:
      "• Understood requirements and analysed source and target system.\n" +
      "• Generated data mapping documents for an ETL process.\n" +
      "• Tasked with modifying a piece of SQL code, which not only completed successfully, but also came up with some recommendations.\n" +
      "• Participated in executing test cases for a BI solution.\n" +
      "• Grasped concepts of Software Development Life Cycle(SDLC) and applied it throughout the internship.\n" +
      "• Familiarised with the basics of Master Data Management and performed a gap analysis on a task.",
    buttonText: "",
    date: "Jan 2020 - Apr 2020",
    icon: "work",
  },
  {
    id: 2,
    title: "Y1S1",
    location: "National University of Singapore (NUS)",
    description:
      "• CS1101S Programming Methodology: A-\n" +
      "• CS1231 Discrete Structures: B+\n" +
      "• MA1101R Linear Algebra I: A-",
    buttonText: "",
    date: "Aug 2020 - Dec 2020",
    icon: "school",
  },
  {
    id: 3,
    title: "Y1S2",
    location: "National University of Singapore (NUS)",
    description:
      "• Dean's List AY20/21 Semester 2\n" +
      "• CS2040S Data Structures and Algorithms: A\n" +
      "• CS2030S Programming Methodology II: A\n" +
      "• CS1010R Programming Methodology: A+\n" +
      "• IS1103 Ethics in Computing: A-\n" +
      "• PC1431 Physics IE: A\n" +
      "• GER1000 Quantitative Reasoning: A",
    buttonText: "View my Dean's List Certificate",
    buttonLink:
      "https://credentials.nus.edu.sg/215870ac-06f5-48b6-958b-e9b4bf1e568f",
    date: "Jan 2021 - May 2021",
    icon: "school",
  },
  {
    id: 4,
    title: "Chatella",
    location: "NUS CP2106 Independent Software Development Project (Orbital)",
    description:
      "• Developed a peer-to-peer-like messaging application called Chatella during my summer holidays.\n" +
      "• Worked on both the backend and frontend, as well as the integration.\n" +
      "• Tech Stack: reactJS, nodeJS, firebase, socketIO",
    buttonText: "View Chatella's Source Code (GitHub)",
    buttonLink: "https://github.com/s7u4rt99/chat-ella",
    date: "May 2021 - Aug 2021",
    icon: "project",
  },
  {
    id: 5,
    title: "Y2S1",
    location: "National University of Singapore (NUS)",
    description:
      "• CS2103T Software Engineering: A+, top 5% of cohort\n" +
      "• CS2100 Computer Organisation: A+\n",
    buttonText: "",
    date: "Aug 2021 - Dec 2021",
    icon: "school",
  },
  {
    id: 6,
    title: "Philantrofi",
    location: "NUS Social Impact Catalyst",
    description:
      "• Payment service for online marketplaces where consumers can donate to charities and pay for their goods at the same time.\n" +
      "• Allows philanthropists to donate to organisations through our website.\n" +
      "• I am part of the backend team, in charge of desigining and developing the database." +
      "\n• Tech Stack: reactJS, nextJS, postgresql",
    buttonText: "",
    date: "Oct 2021 - May 2022",
    icon: "project",
  },
  {
    id: 7,
    title: "Peggle Clone IOS",
    location:
      "NUS CS3217: Software Engineering on Modern Application Platforms",
    description:
      '• Built a clone of the famous "Peggle" game using SwiftUI on iPad.\n' +
      "• Implemented using good protocol orientated programming practices as well as following the MVVM framework.\n" +
      "• Developed the physics engine and game engine from scratch without the help of third party libraries eg SpriteKit.\n" +
      "• Implemented intersect detection and collision resolutions of objects in the game.\n" +
      "• Developed special powered objects, point system, beautification of the game\n" +
      "• Tech Stack: SwiftUI",
    buttonText: "View Peggle Clone's Source Code (GitHub)",
    buttonLink: "https://github.com/s7u4rt99/peggle-ios-clone",
    date: "Jan 2022 - Mar 2022",
    icon: "project",
  },
  {
    id: 8,
    title: "CardBox IOS",
    location:
      "NUS CS3217: Software Engineering on Modern Application Platforms",
    description:
      "• Built an application called CardBox, which contains a variety of card games (online and offline) for users to play.\n" +
      "• Developed a fully functional product after 3 x 2 week sprints.\n" +
      "• Implemented following good protocol orientated programming practices as well as following the MVVM framework.\n" +
      "• Created an extensible card game and game runner framework.\n" +
      "• Used the card game framework to develop 2 very different games, Exploding Kittens and Monopoly Deal\n" +
      "• Implemented the online multiplayer portion, including joining and hosting games, using firebase firestore\n" +
      "• Tech Stack: SwiftUI, Firebase Firestore",
    buttonText: "View CardBox's Source Code (GitHub)",
    buttonLink: "https://github.com/cs3217-2122/group-project-cardbox",
    date: "Mar 2022 - May 2022",
    icon: "project",
  },
  {
    id: 9,
    title: "CS2030S Programming Methodology II Teaching Assistant",
    location: "National University of Singapore (NUS)",
    description:
      "• Taught a class of 12 students about OOP principles and practices using Java.\n" +
      "• Guided the class to complete 11 Lab sessions.\n" +
      "• Prepared the class for their midterms, 2 practical examinations, and final examination.\n" +
      "• Conducted weekly 1hr consultation sessions with students.\n" +
      "• Marked Lab assignments using bash scripts. \n",
    buttonText: "",
    date: "Jan 2022 - May 2022",
    icon: "work",
  },
  {
    id: 10,
    title: "Y2S2",
    location: "National University of Singapore (NUS)",
    description:
      "• Dean's List AY21/22 Semester 2\n" +
      "• CS3217 Software Engineering on Modern Application Platforms: A\n" +
      "• CS2107 Introduction to Computer Security: A \n" +
      "• CS2105 Introduction to Computer Networks: A\n" +
      "• CS2102 Database Systems: A-\n" +
      "• ST2334 Probability and Statistics: A\n",
    buttonText: "View my Dean's List Certificate",
    buttonLink:
      "https://credentials.nus.edu.sg/c86d99d7-d063-44a3-a7e7-6a4151a1c9d0",
    date: "Jan 2022 - May 2022",
    icon: "school",
  },
  {
    id: 11,
    title: "AI Software Engineer Intern",
    location: "DSO Laboratories",
    description:
      "In MERLIN, we aim to create a full-stack, web-based, artificial intelligence-powered video editing tool.\n" +
      "The system will involve 2 aspects: \n" +
      "1) developing a modern full-stack web-based video editing tool \n" +
      "2) applying a basket of machine learning models and algorithms across Natural Language Processing \n" +
      "and Computer Vision research to create ‘MERLIN’s spells’, for example AI-green-screening and video inpainting.",
    buttonText: "",
    date: "May 2022 - Jul 2022",
    icon: "work",
  },
  {
    id: 12,
    title: "Giving Coupons",
    location: "NUS CS3216: ",
    description:
      "•	Developed a platform to increase awareness of different charities in Singapore.\n" +
      "•	Designed and implemented a platform for donors to donate the money to us, and we would split the amount into smaller amounts in the form of vouchers, spreading it across Singapore. Recipients of these vouchers can go to our platform, where they can view the different charities and choose one to donate the voucher to. They would be able to donate some of their own money too, creating a multiplier effect and increasing awareness of charities.\n" +
      "•	Built both the front end and back end of the project.\n" +
      "•	Designed the database architecture, for the donation vouchers and charities.\n" +
      "•	Implemented end points for CRUD API.\n" +
      "•	Tech Stack: ReactJS, NodeJS, Express",
    buttonText: "View Giving Coupons Source Code (GitHub)",
    buttonLink: "https://github.com/cs3216/2022-a3-2022-a3-group-1",
    date: "Sept 2022 - Present",
    icon: "project",
  },
  {
    id: 13,
    title: "Project Juniors Club",
    location: "Google Developer Student Club",
    description:
      "•	Project under NUS Google Developer Student Club.\n" +
      "•	Partnered with Food Bank SG to develop an e-learning system that includes an admin portal for internal staff and an e-learning portal for the targeted audience, educating about food wastage and security.\n" +
      "•	Designed and developed end points for the backend CRUD API.\n" +
      "•	Implemented the state management system for the front end using recoil.\n" +
      "•	Developed the front end of the e-learning portal using ReactJS.\n" +
      "•	Maintained the backend database architecture using Prisma ORM.\n" +
      "•	Tech Stack: ReactJS, Chakra UI, NextJS, Prisma, NodeJS, NextAuth, Checkout.com",
    buttonText: "View Project Juniors Club Source Code (Github)",
    buttonLink: "https://github.com/Project-Juniors-Club/jc-app",
    date: "Aug 2022 - Present",
    icon: "project",
  },
];

export default experienceData;
