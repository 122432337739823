import styles from "./About.module.css";
import me from "../../images/s7u4rt99.png";

function About() {
  return (
    <div className={styles.about}>
      <div className={styles.aboutInner}>
        <div className={styles.avatarBox}>
          <img className={styles.avatar} src={me} alt="me" />
        </div>
        <p className={styles.aboutDesc}>
          I am an aspiring software developer, planning to specialise in
          Software Engineering and Artificial Intelligence.
        </p>
      </div>
    </div>
  );
}

export default About;
