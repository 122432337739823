import { TextField } from "@material-ui/core";
import styles from "./Contact.module.css";
import emailjs from "emailjs-com";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import github from "../../images/GithubBlack.png";
import linkedin from "../../images/linkedin_black_logo_icon_147114.png";
import instagram from "../../images/InstagramBlack-removebg-preview.png";

function Contact() {
  const [loading, setLoading] = useState(false);

  function sendEmail(e) {
    e.preventDefault();
    setLoading(true);
    const { name, subject, email, message } = e.target.elements;
    const fieldValues = [name.value, email.value, subject.value, message.value];
    const fieldNames = ["Name", "Email", "Subject", "Message"];
    const emptyFields = [];

    for (let i = 0; i < fieldValues.length; i++) {
      if (!fieldValues[i]) {
        emptyFields.push(fieldNames[i]);
      }
    }

    if (emptyFields.length !== 0) {
      let alertMessage = "Please ensure ";
      for (let i = 0; i < emptyFields.length; i++) {
        const isOrAre = emptyFields.length === 1 ? " is" : " are";

        alertMessage += emptyFields[i];
        if (i === emptyFields.length - 1) {
          alertMessage += isOrAre + " not empty.";
        } else if (i === emptyFields.length - 2) {
          alertMessage += " and ";
        } else {
          alertMessage += ", ";
        }
      }
      alert(alertMessage);
    } else {
      emailjs
        .sendForm(
          "service_kriotgl",
          "template_tf48b14",
          e.target,
          "user_h9C6o8lU28nP3RkI7Bl8j"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("Your email has been sent");
          },
          (error) => {
            console.log(error.text);
            alert("There has been an error sending your email. (error.text)");
          }
        );
      e.target.reset();
    }

    setLoading(false);
  }
  return (
    <>
      <div className={styles.contactContainer}>
        <h1 className={styles.contactTitle}> Send me a message! </h1>
        <div className={styles.body}>
          <form className={styles.contactForm} onSubmit={sendEmail}>
            <TextField
              id="name"
              className={styles.name}
              label="Name"
              variant="filled"
              name="name"
              type="text"
            />
            <TextField
              id="email"
              className={styles.email}
              label="Email"
              variant="filled"
              name="email"
              type="email"
            />
            <TextField
              className={styles.subject}
              id="subject"
              label="Subject"
              variant="filled"
              name="subject"
              type="text"
            />
            <TextField
              id="message"
              className={styles.message}
              type="text"
              label="Message"
              placeholder="Send me a message"
              name="message"
              variant="filled"
              rows={15}
              multiline
            />
            <LoadingButton
              className={styles.button}
              type="submit"
              endIcon={<SendIcon />}
              loading={loading}
              loadingPosition="end"
              variant="contained"
            >
              Send
            </LoadingButton>
          </form>
          <div className={styles.rightHalf}>
            <h2>Or find me at: </h2>
            <div className={styles.icons}>
              <span className={styles.githubWrapper}>
                <a
                  href="https://github.com/s7u4rt99"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.github, styles.hvrHang].join(" ")}
                    src={github}
                    alt="github"
                  />
                </a>
              </span>
              <span className={styles.linkedinWrapper}>
                <a
                  href="https://www.linkedin.com/in/stuart-long-b3b452221/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.linkedin, styles.hvrHang].join(" ")}
                    src={linkedin}
                    alt="linkedin"
                  />
                </a>
              </span>
              <span className={styles.instagramWrapper}>
                <a
                  href="https://www.instagram.com/_stuart_long_/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.instagram, styles.hvrHang].join(" ")}
                    src={instagram}
                    alt="instagram"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
