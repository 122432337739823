import experienceData from "./ExperienceData";
import styles from "./Experience.module.css";
import { ReactComponent as WorkIcon } from "../../images/timelineIcons/portfolio.svg";
import { ReactComponent as SchoolIcon } from "../../images/timelineIcons/school-_1_.svg";
import { ReactComponent as ProjectIcon } from "../../images/timelineIcons/project.svg";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function Experience() {
  const workIconStyles = { background: "#57523E" };
  const schoolIconStyles = { background: "#D94C1A" };
  const projectIconStyles = { background: "#F2BE24" };

  return (
    <div className={styles.experience}>
      <h1 className={styles.experienceTitle}> Experience </h1>
      <VerticalTimeline>
        {experienceData.map((element) => {
          const isWorkIcon = element.icon === "work";
          const isSchoolIcon = element.icon === "school";
          const showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== "";

          return (
            <VerticalTimelineElement
              key={element.id}
              date={element.date}
              dateClassName="date"
              iconStyle={
                isWorkIcon
                  ? workIconStyles
                  : isSchoolIcon
                  ? schoolIconStyles
                  : projectIconStyles
              }
              icon={
                isWorkIcon ? (
                  <WorkIcon />
                ) : isSchoolIcon ? (
                  <SchoolIcon />
                ) : (
                  <ProjectIcon />
                )
              }
            >
              <h3 className="vertical-timeline-element-title">
                {element.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {element.location}
              </h5>
              <p className="description">
                {element.description.split("\n").map((line) => (
                  <div>{line}</div>
                ))}
              </p>
              {showButton && (
                <>
                  <br />
                  <a
                    className={`${styles.button} ${
                      isWorkIcon
                        ? styles.workButton
                        : isSchoolIcon
                        ? styles.schoolButton
                        : styles.projectButton
                    } ${styles.hvrForward}`}
                    href={element.buttonLink}
                  >
                    {element.buttonText}
                  </a>
                </>
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
