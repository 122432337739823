import styles from "./Home.module.css";
import me from "../../images/s7u4rt99.png";
import github from "../../images/GithubBlack.png";
import linkedin from "../../images/linkedin_black_logo_icon_147114.png";
import instagram from "../../images/InstagramBlack-removebg-preview.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Home() {
  return (
    <div className={styles.home}>
      <div className={styles.homeInner}>
        <div className={styles.main}>
          <div className={styles.summary}>
            <p className={styles.homeDesc}>
              Hello! My name is Stuart Long and I am a Year 2 Computer Science
              Undergraduate at the National University of Singapore.
            </p>
            <div className={styles.icons}>
              <span className={styles.githubWrapper}>
                <a
                  href="https://github.com/s7u4rt99"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.github, styles.hvrHang].join(" ")}
                    src={github}
                    alt="github"
                  />
                </a>
              </span>
              <span className={styles.linkedinWrapper}>
                <a
                  href="https://www.linkedin.com/in/stuart-long-b3b452221/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.linkedin, styles.hvrHang].join(" ")}
                    src={linkedin}
                    alt="linkedin"
                  />
                </a>
              </span>
              <span className={styles.instagramWrapper}>
                <a
                  href="https://www.instagram.com/_stuart_long_/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={[styles.instagram, styles.hvrHang].join(" ")}
                    src={instagram}
                    alt="instagram"
                  />
                </a>
              </span>
            </div>
          </div>
          <div className={styles.avatarBox}>
            <img className={styles.avatar} src={me} alt="me" />
          </div>
        </div>
        <KeyboardArrowDownIcon
          className={[styles.arrow, styles.hvrHang].join(" ")}
          onClick={() =>
            document
              .getElementById("about")
              .scrollIntoView({ behavior: "smooth" })
          }
        />
      </div>
    </div>
  );
}

export default Home;
