import styles from "./Skills.module.css";
import html from "../../images/HTML-removebg-preview.png";
import css from "../../images/CSS-removebg-preview.png";
import js from "../../images/JS-removebg-preview.png";
import java from "../../images/Java.png";
import python from "../../images/Python-removebg-preview.png";
import react from "../../images/React-removebg-preview.png";

function Skills() {
  return (
    <div className={styles.skills}>
      <h3 className={styles.skillTitle}>Skills</h3>
      <div classNam={styles.skillIcons}>
        <div className={styles.skillIconFirstRow}>
          <img className={styles.htmlIcon} src={html} alt="html" />
          <img className={styles.cssIcon} src={css} alt="css" />
          <img className={styles.jsIcon} src={js} alt="javascript" />
        </div>
        <div className={styles.skillIconSecondRow}>
          <img className={styles.javaIcon} src={java} alt="java" />
          <img className={styles.pythonIcon} src={python} alt="python" />
          <img className={styles.reactIcon} src={react} alt="react" />
        </div>
      </div>
    </div>
  );
}

export default Skills;
